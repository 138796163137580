// 就是整个应用的路由管理器配置文件
import Vue from 'vue'
import VueRouter from 'vue-router'
// 配置vue应用使用 路由管理器
Vue.use(VueRouter)

const routerArr = [

    {
        path: 'home',
        component: () => import('@/views/Toya/Home/Home.vue'),
        meta: {
            title: "首页"
        },
        beforeEnter: (to, from, next) => {
            if (from.meta.title === '登录') {
                next()
                setTimeout(() => {
                    window.location.reload();

                }, 500)

            } else {
                next()
            }
            // console.log(to, from);

        }
    },
    {
        path: 'PFieldsList',
        component: () => import('@/views/Toya/PFieldsList/PFieldsList.vue'),
        meta: {
            title: "属性管理"
        }
    },
    {
        path: 'FrOnticList',
        component: () => import('@/views/Toya/Fr/FrOnticList/FrOnticList.vue'),
        meta: {
            title: "单体"
        }
    },
    {
        path: 'FrDropDown',
        component: () => import('@/views/Toya/Fr/FrDropDown/FrDropDown.vue'),
        meta: {
            title: "下拉菜单源"
        }
    },
    {
        path: 'FrDropDownAdd1',
        name: 'frDropDownAdd1',
        component: () => import('@/views/Toya/Fr/FrDropDown/FrDropDownAdd1.vue'),
        meta: {
            title: "FrDropDownAdd1"
        }
    },
    {
        path: 'FrDropDownAdd2',
        name: 'frDropDownAdd2',
        component: () => import('@/views/Toya/Fr/FrDropDown/FrDropDownAdd2.vue'),
        meta: {
            title: "FrDropDownAdd2"
        }
    },
    {
        path: 'FrDropDownDetail/:row',
        name: 'frDropDownDetail',
        component: () => import('@/views/Toya/Fr/FrDropDown/FrDropDownDetail.vue'),
        meta: {
            title: "FrDropDownDetail"
        }
    },
    {
        path: 'FrDropDownUpdate1/:row',
        name: 'frDropDownUpdate1',
        component: () => import('@/views/Toya/Fr/FrDropDown/FrDropDownUpdate1.vue'),
        meta: {
            title: "FrDropDownUpdate1"
        }
    },
    {
        path: 'FrDropDownUpdate2',
        name: 'frDropDownUpdate2',
        component: () => import('@/views/Toya/Fr/FrDropDown/FrDropDownUpdate2.vue'),
        meta: {
            title: "FrDropDownUpdate2"
        }
    },
    {
        path: 'FrRelList',
        component: () => import('@/views/Toya/Fr/FrRelList/FrRelList.vue'),
        meta: {
            title: "关联"
        }
    },
    {
        path: 'FrOmList',
        component: () => import('@/views/Toya/Fr/FrOmList/FrOmList.vue'),
        meta: {
            title: "一对一或多"
        }
    },
    {
        path: 'FrOmListUpdate/:id',
        name: 'frOmListUpdate',
        component: () => import('@/views/Toya/Fr/FrOmList/FrOmListUpdate.vue'),
        meta: {
            title: "FrOmListUpdate"
        }
    },
    {
        path: 'FrOmListDetail/:id',
        name: 'frOmListDetail',
        component: () => import('@/views/Toya/Fr/FrOmList/FrOmListDetail.vue'),
        meta: {
            title: "FrOmListDetail"
        }
    },
    {
        path: 'FrSqlBList',
        component: () => import('@/views/Toya/Fr/FrSqlBList/FrSqlBList.vue'),
        meta: {
            title: "SQL体"
        }
    },
    {
        path: 'SimpleGridList',
        component: () => import('@/views/Toya/Grid/SimpleGridList/SimpleGridList.vue'),
        meta: {
            title: "简单页格"
        }
    },
    {
        path: 'SimpleGridList/:id',
        name: 'simpleGridListDetail',
        component: () => import('@/views/Toya/Grid/SimpleGridList/SimpleGridListDetail.vue'),
        meta: {
            title: "SimpleGridListDetail"
        }
    },
    {
        path: 'GridList',
        component: () => import('@/views/Toya/Grid/GridList/GridList.vue'),
        meta: {
            title: "列表页格"
        }
    },
    {
        path: 'GridListUpdate1/:row',
        name: 'gridListUpdate1',
        component: () => import('@/views/Toya/Grid/GridList/GridListUpdate1.vue'),
        meta: {
            title: "GridListUpdate1"
        }
    },
    {
        path: 'GridListUpdate2',
        name: 'gridListUpdate2',
        component: () => import('@/views/Toya/Grid/GridList/GridListUpdate2.vue'),
        meta: {
            title: "GridListUpdate2"
        }
    },
    {
        path: 'GridListDetail/:id',
        name: 'gridListDetail',
        component: () => import('@/views/Toya/Grid/GridList/GridListDetail.vue'),
        meta: {
            title: "GridListDetail"
        }
    },
    {
        path: 'ImageGridList',
        component: () => import('@/views/Toya/Grid/ImageGridList/ImageGridList.vue'),
        meta: {
            title: "图表页格"
        }
    },
    {
        path: 'BarCustomUi',
        component: () => import('@/views/Toya/Ui/BarCustomUi/BarCustomUi.vue'),
        meta: {
            title: "条形区自定义UI"
        }
    },
    {
        path: 'TemplateDetailUi',
        component: () => import('@/views/Toya/Ui/TemplateDetailUi/TemplateDetailUi.vue'),
        meta: {
            title: "模板可编辑UI"
        }
    },
    {
        path: 'MappingRules',
        component: () => import('@/views/Toya/Process/MappingRules.vue'),
        meta: {
            title: "映射及计算规则"
        }
    },
    {
        path: 'ChangingProcess',
        component: () => import('@/views/Toya/Process/ChangingProcess.vue'),
        meta: {
            title: "事物迁变流程"
        }
    },
    {
        path: 'UiAuthorize',
        component: () => import('@/views/Toya/Sys/UiAuthorize.vue'),
        meta: {
            title: "UI授权管理"
        }
    },
    {
        path: 'MenuAddress',
        component: () => import('@/views/Toya/Sys/MenuAddress.vue'),
        meta: {
            title: "菜单地址管理"
        }
    },
    {
        path: 'DictionaryQuery',
        component: () => import('@/views/Toya/Sys/DictionaryQuery/DictionaryQuery.vue'),
        meta: {
            title: "字典表查询"
        }
    },
    // {
    //     path: 'ProjectEditing',
    //     component: () => import('@/views/Toya/Sys/ProjectEditing/ProjectEditing.vue'),
    //     meta: {
    //         title: "项目参数编辑"
    //     }
    // },
    {
        path: 'PFieldsListUpdate/:id',
        name: 'pfieldsListUpdate',
        component: () => import('@/views/Toya/PFieldsList/PFieldsListUpdate.vue'),
        meta: {
            title: "PFieldsListUpdate"
        }
    },
    {
        path: 'PFieldsDetail/:id',
        name: 'pfieldsDetail',
        component: () => import('@/views/Toya/PFieldsList/PFieldsDetail.vue'),
        meta: {
            title: "PFieldsDetail"
        }
    },
    {
        path: 'PFieldsListAdd',
        name: 'pfieldsListAdd',
        component: () => import('@/views/Toya/PFieldsList/PFieldsListAdd.vue'),
        meta: {
            title: "PFieldsListAdd"
        }
    },
    {
        path: 'FrOnticListAdd',
        name: 'frOnticListAdd',
        component: () => import('@/views/Toya/Fr/FrOnticList/FrOnticListAdd.vue'),
        meta: {
            title: "FrOnticListAdd"
        }
    },
    {
        path: 'FrOnticListUpdate/:id',
        name: 'frOnticListUpdate',
        component: () => import('@/views/Toya/Fr/FrOnticList/FrOnticListUpdate.vue'),
        meta: {
            title: "FrOnticListUpdate"
        }
    },
    {
        path: 'FrOnticListDetail/:id',
        name: 'frOnticListDetail',
        component: () => import('@/views/Toya/Fr/FrOnticList/FrOnticListDetail.vue'),
        meta: {
            title: "FrOnticListDetail"
        }
    },
    {
        path: 'FrOnticListProps/:id',
        name: 'frOnticListProps',
        component: () => import('@/views/Toya/Fr/FrOnticList/FrOnticListProps.vue'),
        meta: {
            title: "FrOnticListProps"
        }
    },
    {
        path: 'FrRelListAdd1',
        name: 'frRelListAdd1',
        component: () => import('@/views/Toya/Fr/FrRelList/FrRelListAdd1.vue'),
        meta: {
            title: "FrRelListAdd1"
        }
    },
    {
        path: 'FrRelListAdd2',
        name: 'frRelListAdd2',
        component: () => import('@/views/Toya/Fr/FrRelList/FrRelListAdd2.vue'),
        meta: {
            title: "FrRelListAdd2"
        }
    },
    {
        path: 'FrRelListDetail',
        name: 'frRelListDetail',
        component: () => import('@/views/Toya/Fr/FrRelList/FrRelListDetail.vue'),
        meta: {
            title: "FrRelListDetail"
        }
    },
    {
        path: 'FrRelListUpdate1/:row',
        name: 'frRelListUpdate1',
        component: () => import('@/views/Toya/Fr/FrRelList/FrRelListUpdate1.vue'),
        meta: {
            title: "FrRelListUpdate1"
        }
    },
    {
        path: 'FrRelListUpdate2',
        name: 'frRelListUpdate2',
        component: () => import('@/views/Toya/Fr/FrRelList/FrRelListUpdate2.vue'),
        meta: {
            title: "FrRelListUpdate2"
        }
    },
    {
        path: 'FrSqlBListAdd1',
        name: 'frSqlBListAdd1',
        component: () => import('@/views/Toya/Fr/FrSqlBList/FrSqlBListAdd1.vue'),
        meta: {
            title: "FrSqlBListAdd1"
        }
    },
    {
        path: 'FrSqlBListAdd2',
        name: 'frSqlBListAdd2',
        component: () => import('@/views/Toya/Fr/FrSqlBList/FrSqlBListAdd2.vue'),
        meta: {
            title: "FrSqlBListAdd2"
        }
    },
    {
        path: 'FrSqlBListUpdate1/:row',
        name: 'frSqlBListUpdate1',
        component: () => import('@/views/Toya/Fr/FrSqlBList/FrSqlBListUpdate1.vue'),
        meta: {
            title: "FrSqlBListUpdate1"
        }
    },
    {
        path: 'FrSqlBListUpdate2',
        name: 'frSqlBListUpdate2',
        component: () => import('@/views/Toya/Fr/FrSqlBList/FrSqlBListUpdate2.vue'),
        meta: {
            title: "FrSqlBListUpdate2"
        }
    },
    {
        path: 'FrSqlBListDetail/:id',
        name: 'frSqlBListDetail',
        component: () => import('@/views/Toya/Fr/FrSqlBList/FrSqlBListDetail.vue'),
        meta: {
            title: "FrSqlBListDetail"
        }
    },
    {
        path: 'GridSimpleUpdate1/:row',
        name: 'gridSimpleUpdate1',
        component: () => import('@/views/Toya/Grid/SimpleGridList/GridSimpleUpdate1.vue'),
        meta: {
            title: "GridSimpleUpdate1"
        }
    },
    {
        path: 'GridSimpleUpdate2',
        name: 'gridSimpleUpdate2',
        component: () => import('@/views/Toya/Grid/SimpleGridList/GridSimpleUpdate2.vue'),
        meta: {
            title: "GridSimpleUpdate2"
        }
    },
    {
        path: 'GridSimpleAdd1',
        name: 'gridSimpleAdd1',
        component: () => import('@/views/Toya/Grid/SimpleGridList/GridSimpleAdd1.vue'),
        meta: {
            title: "GridSimpleAdd1"
        }
    },
    {
        path: 'GridSimpleAdd2',
        name: 'gridSimpleAdd2',
        component: () => import('@/views/Toya/Grid/SimpleGridList/GridSimpleAdd2.vue'),
        meta: {
            title: "GridSimpleAdd2"
        }
    },
    {
        path: 'GridListAdd',
        name: 'gridListAdd',
        component: () => import('@/views/Toya/Grid/GridList/GridListAdd.vue'),
        meta: {
            title: "GridListAdd"
        }
    },
    {
        path: 'GridListAdd2',
        name: 'gridListAdd2',
        component: () => import('@/views/Toya/Grid/GridList/GridListAdd2.vue'),
        meta: {
            title: "GridListAdd2"
        }
    },
    {
        path: 'BarCustomUiAdd',
        name: 'barCustomUiAdd',
        component: () => import('@/views/Toya/Ui/BarCustomUi/BarCustomUiAdd.vue'),
        meta: {
            title: "BarCustomUiAdd"
        }
    },
    {
        path: 'BarCustomUiAdd2',
        name: 'barCustomUiAdd2',
        component: () => import('@/views/Toya/Ui/BarCustomUi/BarCustomUiAdd2.vue'),
        meta: {
            title: "BarCustomUiAdd2"
        }
    },
    {
        path: 'BarCustomUpdate1/:row',
        name: 'barCustomUpdate1',
        component: () => import('@/views/Toya/Ui/BarCustomUi/BarCustomUpdate1.vue'),
        meta: {
            title: "BarCustomUpdate1"
        }
    },
    {
        path: 'BarCustomUpdate2',
        name: 'barCustomUpdate2',
        component: () => import('@/views/Toya/Ui/BarCustomUi/BarCustomUpdate2.vue'),
        meta: {
            title: "BarCustomUpdate2"
        }
    },
    {
        path: 'BarCustomDetail',
        name: 'barCustomDetail',
        component: () => import('@/views/Toya/Ui/BarCustomUi/BarCustomDetail.vue'),
        meta: {
            title: "BarCustomDetail"
        }
    },
    {
        path: 'Build',
        name: 'Build',
        component: () => import('@/views/Toya/Build/projectBuild/Build.vue'),
        meta: {
            title: "项目生成"
        }
    },
    {
        path: 'initialise',
        name: 'initialise',
        component: () => import('@/views/Toya/Build/Initialise/initialise.vue'),
        meta: {
            title: "初始化"
        }
    },
]

// 配置路由信息,并将其暴露出去
export default new VueRouter({
    // 路由模式 history / hash
    mode: 'hash',
    // 路由信息配置到当前位置
    routes: [
        /*
        一个{}，代表一个路由配置
         */
        {
            path: '/',
            component: () => import('@/views/Login/Login.vue'),
            alias: '/login',
            // requiresAuth: true,
            meta: {
                title: "登录"
            }
        },
        {
            path: '/test',
            component: () => import('@/views/test.vue')
        },
        {
            path: '/register',
            component: () => import('@/views/Login/Register/register.vue'),
            // requiresAuth: true,
            meta: {
                title: "注册"
            }
        },
        {
            path: '/resetPasswords',
            component: () => import('@/views/Login/ResetPasswords/resetPasswords.vue'),
            // requiresAuth: true,
            meta: {
                title: "重置密码"
            }
        },
        {
            path: '/toya',
            component: () => import('@/views/Toya/Toya.vue'),
            // alias:'/home',
            // requiresAuth: true,
            meta: {
                title: "Toya"
            },
            children: routerArr
        },
    ]
})

