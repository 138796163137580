<template>
  <div class="box" v-cloak>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style>
/*公共样式文件*/
div{
  box-sizing: border-box;
}
a {
  color: orange;
  text-decoration: none;
}

[v-cloak] {
  display: none;
}

ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
}


</style>