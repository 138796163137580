import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
// 导入路由管理器
import router from "./router/index"

// 弹窗时页面滚动条抖动
ElementUI.Dialog.props.lockScroll.default = false

Vue.config.productionTip = false

// 定义全局方法
/*
 list是一个数组=>list中需要含有(name，value，label)，选填[msg] 
 1.返回值为1，则表示通过验证 黄色的警告提示
 */
Vue.prototype.$tyMsgW = function (list) {
  for (const e of list) {
    if (!e.value) {
      if (!e.msg) {
        e.msg = e.label + '不能为空!'
      }
      this.$message({
        message: e.msg,
        type: 'warning'
      });
      return 0
    }
  }
  return 1
};

/*
遮罩层的显示与隐藏
*/
Vue.prototype.$setLoading = function () {
  const loading = this.$loading({
    lock: true,
    text: '拼命加载中...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.3)'
  })
  return loading
}

Vue.prototype.$removeLoading = function (loading) {
  loading.close();
}

Vue.use(ElementUI);

Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});


new Vue({
  render: h => h(App),
  // 将导入的路由管理器配置到vue 实例中
  router

}).$mount('#app')
